/**
 * Handle Menu levels
 * @param {String || DOM element} menuContainer string or DOM element for menu container
 * @sample traverseMenu('[data-main-menu]');
 * @sample traverseMenu(document.querySelector('[data-main-menu]'));
 * @returns void
 */
export default function traverseMenu(menuContainer) {
    if (typeof menuContainer === 'string') {
        menuContainer = document.querySelector(menuContainer);
    }

    if (!menuContainer) {
        return;
    }

    /**
     * Handle traverse back
     * @param {DOM} btn Dom element
     */
    function handleBackButtonClick(btn) {
        const parent = btn.closest('.is-active');
        if (parent) {
            parent.classList.remove('is-active', 'last-active');
            const lastActive = parent.closest('.is-active');
            if (lastActive) {
                lastActive.classList.add('last-active');
            }
        }
    }

    /**
     * Handle traverse forward
     * @param {DOM} btn Dom element
     */
    function handleForwardButtonClick(btn) {
        const parent = btn.closest('.is-active');
        if (parent) {
            parent.classList.remove('last-active');
        }
        const nextElement = btn.nextElementSibling;
        if (nextElement) {
            nextElement.classList.add('is-active', 'last-active');
        }
    }

    const backButtonElements = menuContainer.querySelectorAll('[data-menu-back]');
    const forwardButtonElements = menuContainer.querySelectorAll('[data-menu-forward]');

    backButtonElements.forEach(function (btn) {
        btn.addEventListener('click', function (e) {
            e.preventDefault();
            handleBackButtonClick(btn);
        });
    });

    forwardButtonElements.forEach(function (btn) {
        btn.addEventListener('click', function (e) {
            e.preventDefault();
            handleForwardButtonClick(btn);
        });
    });
}