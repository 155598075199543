/**
 * Helper functions for GTM tracking
 * Functions supply data to GTM via dataLayer variable
 * Data is then sent to GTM for analytics team to consume
 * Sample usage:
 * import { dlInit, dlNinjaForms, dlHubSpotForms, dlRemoveFromCart, dlVimeoResumptions } from './ksource-datalayer.js';
 *
 * jQuery(document).ready(function () {
 *     dlInit();
 *     dlNinjaForms();
 *     dlHubSpotForms();
 *     dlRemoveFromCart();
 *     dlVimeoResumptions();
 *  });
 * 
 * @version 1.0.0
 */

/**
 * Set session data and data layer array
 */
function dlInit() {

    window.dataLayer = window.dataLayer || [];

    if (!sessionStorage.getItem('km_session')) {
        const queryParams = getQueryParams();
        const sessionData = JSON.stringify({
            'referer': document.referrer || window.frames.top.document.referrer || 'unknown source',
            ...queryParams
        });
        sessionStorage.setItem('km_session', objectToBase64(sessionData));
    }

    dataLayer.push({
        "event": 'km_session_data_captured',
        "km_source_dlv": {
            ...JSON.parse(base64ToObject(sessionStorage.getItem('km_session'))),
        }
    });

}

/**
 * Handle all Ninja Form submissions
 */
function dlNinjaForms() {
    jQuery(document).on('nfFormReady', function () {
        nfRadio.channel('forms').on('submit:response', function (form) {
            captureFormData(form, 'km_all_form_submissions');
        });
    });
}

/**
 * Handles submissions of hubspot forms
 */
function dlHubSpotForms() {
    if (typeof hbspt !== 'undefined') {

        handleHubspotForm();

        hbspt.ketchup = {
            onBeforeFormInit: (ctx) => {
                const data = sessionStorage.getItem('km_session');

                if (!data) {
                    return;
                }

                const items = JSON.parse(base64ToObject(data));
                const urlParams = new URLSearchParams(window.location.search);

                for (let key in items) {
                    if (key.startsWith('utm_')) {
                        urlParams.set(key, items[key]);
                    }
                }

                const updatedQueryString = urlParams.toString();
                const baseUrl = window.location.origin + window.location.pathname;
                const updatedUrl = baseUrl + (updatedQueryString ? '?' + updatedQueryString : '');
                window.history.pushState({ path: updatedUrl }, '', updatedUrl);
            },
            onFormSubmitted: (form, data) => {
                const context = "hs_context" in data.submissionValues ? JSON.parse(data.submissionValues.hs_context) : {};

                dataLayer.push({
                    "event": 'km_all_form_submissions',
                    "km_source_dlv": {
                        "time": new Date().toLocaleString(),
                        "page_title": document.title,
                        "portal_id": context.originalEmbedContext.portalId || 'n/a',
                        "form_id": context.originalEmbedContext.formId || 'n/a',
                        "page_url": window.location.origin + window.location.pathname,
                        "url_params": context.urlParams,
                        ...JSON.parse(base64ToObject(sessionStorage.getItem('km_session'))),
                        "form_vendor": 'HubSpot'
                    }
                });
            },
            onFormReady: form => {
                const data = sessionStorage.getItem('km_session');
                const utmField = form[0].querySelector('input[name="km_utm_source_hubspot"]');

                if (!data || !utmField) {
                    return;
                }

                const items = JSON.parse(base64ToObject(data));
                const urlParams = new URLSearchParams(window.location.search);

                for (let key in items) {
                    if (key.startsWith('utm_')) {
                        urlParams.set(key, items[key]);
                    }
                }

                const updatedQueryString = urlParams.toString();

                utmField.value = updatedQueryString;
                utmField.dispatchEvent(new Event('input', { bubbles: true }));
            }
        }
    }
}

/**
 * Sent data when item is removed from the cart
 */
function dlRemoveFromCart() {
    setTimeout(() => { // Not the best solution 
        const productRows = document.querySelectorAll('.wc-block-cart-item__product');
        productRows.forEach(row => {
            row.addEventListener('click', e => {
                const { target } = e;
                if (target.classList.contains('wc-block-cart-item__remove-link')) {
                    const productData = row.querySelector('.wc-block-components-product-name');
                    if (productData) {
                        dataLayer.push({
                            "event": 'remove_from_cart',
                            "km_source_dlv": {
                                "product_title": productData.innerText,
                                "product_link": productData.getAttribute('href')
                            }
                        })
                    }
                }
            });
        })
    }, 200)
}

function dlVimeoResumptions() {
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach(iframe => {
        const src = iframe.src;

        if (src.includes('player.vimeo')) {
            loadVimeoScript(() => {
                captureVimeoResumptions(iframe);
            });
        }
    });
}

/**
 * Capture how many times video "resumed" before it ended
 * @param {DOM Element} iframe Iframe for vimeo video
 */
function captureVimeoResumptions(iframe) {

    const player = new Vimeo.Player(iframe);
    let resumeCount = 0;
    let videoTitle = '';

    player.getVideoTitle().then(function (title) {
        videoTitle = title;
    });

    player.getLoop().then(function (loop) {
        player.on('play', () => {
            player.getCurrentTime().then(function (seconds) {
                if (seconds > 0) {
                    resumeCount++;
                }
            });
        });

        if (loop) {
            player.setLoop(false).then(function (loop) {
                player.on('ended', () => {
                    player.play();
                });
            });
        }

        player.on('ended', () => {
            dataLayer.push({
                "event": "km_vimeo_resume",
                "km_source_dlv": {
                    "video_title": videoTitle,
                    "resume_count": resumeCount,
                    "video": iframe.src,
                    "page": window.location.href,
                    "is_loop": loop
                }
            });
            resumeCount = 0;
        });
    });
}

/**
 * Send Form information to GTM
 * @param {object} form form object from Ninja forms
 * @param {string} event String identifier for GTM ( record will be saved under this key )
 */
function captureFormData(form, event) {
    const excludeKeys = ['name', 'email', 'submit'];
    const formFields = form.data.fields_by_key || {};
    const fieldData = {};

    for (var key in formFields) {
        if (formFields.hasOwnProperty(key)) {

            if (!excludeKeys.includes(key)) {
                fieldData[key] = formFields[key].value || 'n/a';
            }
        }
    }

    dataLayer.push({
        "event": event, // this is going to be event seen in data layer in GTM
        "km_source_dlv": {
            "form_id": form.data.form_id,
            "form_title": form.data.settings.title,
            "form_source_page": window.location.href,
            "form_vendor": "Ninja Forms",
            "time": new Date().toLocaleString(),
            ...fieldData,
            ...JSON.parse(base64ToObject(sessionStorage.getItem('km_session')))
        }
    })
}

/**
 * Fetch query parameters
 * @returns Object object of query params available on the page
 */
function getQueryParams() {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const queryParams = {};

    params.forEach(function (value, key) {
        if (queryParams.hasOwnProperty(key)) {
            if (!Array.isArray(queryParams[key])) {
                queryParams[key] = [queryParams[key]];
            }
            queryParams[key].push(value);
        } else {
            queryParams[key] = value;
        }
    });

    return queryParams;
}

/**
 * Converts a JavaScript object to a base64-encoded string.
 * @param {Object} obj - The JavaScript object to be converted.
 * @returns {string} - The base64-encoded string representation of the object.
 */
function objectToBase64(obj) {
    const jsonString = JSON.stringify(obj);
    const base64String = btoa(jsonString);
    return base64String;
}

/**
 * Decodes a base64-encoded string to a JavaScript object.
 * @param {string} base64String - The base64-encoded string to be decoded.
 * @returns {Object} - The JavaScript object decoded from the base64 string.
 */
function base64ToObject(base64String) {
    const jsonString = atob(base64String);
    const decodedObject = JSON.parse(jsonString);
    return decodedObject;
}

/**
 * Dynamically loads the Vimeo API script if it's not already loaded.
 *
 * @param {function} callback - The function to be executed after the script is loaded.
 */
function loadVimeoScript(callback) {
    // Check if the Vimeo object is undefined
    if (typeof Vimeo === 'undefined') {
        const script = document.createElement('script');
        script.src = 'https://player.vimeo.com/api/player.js';
        script.onload = callback;
        document.head.appendChild(script);
    } else {
        callback();
    }
}

/**
 * Submit datalayer entry if url contains "submissionGuid" query parameter
 * "submissionGuid" gets added in redirectUrl in hubspot forms once submission is made
 * Knowing this, we check if URL contains this param and push datalayer entry if it does
 * Sample ?submissionGuid=fdc89e8e-4699-4070-8cc2-c154b6be4447
 * 
 * @returns void
 */
function handleHubspotForm() {
    const searchParams = new URLSearchParams(window.location.search);
    const submissionGuid = searchParams.get('submissionGuid');

    if (!submissionGuid) {
        return;
    }

    dataLayer.push({
        "event": 'km_hubspot_form_submission',
        "submissionGuid": submissionGuid,
        ...JSON.parse(base64ToObject(sessionStorage.getItem('km_session')))
    })
}

export {
    dlInit,
    dlNinjaForms,
    dlHubSpotForms,
    dlRemoveFromCart,
    dlVimeoResumptions
}