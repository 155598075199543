/**
 *  Breadcrumbs popup conversion on demand.
 *  Can be triggered via breakpoint (medisaVal) or depending on breadcrumb depth (initOnDepth) X level deep breadcrumb shall be popup
 *  Sample initialization:
 * new Breadcrumbs({
      crumbs: document.getElementsByClassName('km-breadcrumbs')[0],
      triggerExtra: `
        <span class="km-icon km-icon-folder-arrow"></span>
        <span class="km-icon km-icon-folder-cross"></span>
      `,
    });
 */
export default class Breadcrumbs {
    constructor(userProps = {}) {
        this.mediaMatches = false;
        this.defaults = {
            mediaVal: '(max-width:980px)', // Transform crumbs on media value
            initOnDepth: null, // Number, if used crumbs will transform if length is X (priority over mediaVal)
            minDepth: 2, // if crumb is less that X levels deep - do not create popup
            resetOnEsc: true, // Denotes if crumbs popup resets on ESC button press
            initOffclick: true, // Denotes if crumbs popup resets on clicking everywhere but  the crumbs
            crumbs: null, // parent element containing crumbs
            triggerText: 'Reveal breadcrumbs', // Label for the trigger
            triggerExtra: '<span class="km-svg-breadcrumbs"></span>', // Any HTML representing icon if needed
            crumbsClass: 'km-crumbs-is-popup', // If set, class name on this.props.crumbs will be toggled on switch
            onSwitch: null, // run callback on media switch
            onBuild: null, // run callback on popup build
            onRevert: null, // run callback on crumbs reset
        };
        this.props = { ...this.defaults, ...userProps };

        if (!this.isElement(this.props.crumbs)) return 0;

        this.crumbs = [...this.props.crumbs.children];
        this.lastCrumb = this.crumbs.pop();

        if (this.crumbs.length + 1 <= this.props.minDepth) {
            return 0;
        }

        const initOnDepth = this.props.initOnDepth;

        if (Number.isFinite(initOnDepth) && initOnDepth <= this.crumbs.length + 1) {
            this.clearCrumbs();
            if (this.props.crumbsClass) {
                this.props.crumbs.classList.add(this.props.crumbsClass);
            }
            this.buildCrumbs();
        } else {
            this.watchMedia();
        }
    }

    buildCrumbs() {

        const crumbsPopupContainer = document.createElement('li');
        crumbsPopupContainer.classList.add('km-crumbs-popup-container');

        crumbsPopupContainer.appendChild(this.buildTrigger());
        crumbsPopupContainer.appendChild(this.wrapCrumbs());

        this.props.crumbs.appendChild(crumbsPopupContainer);
        this.props.crumbs.appendChild(this.lastCrumb);

        document.addEventListener('click', this.offClick.bind(this), false);
        document.addEventListener('click', this.escClick.bind(this), false);

        this.runOn('onBuild');
    }

    revertCrumbs() {
        this.crumbs.forEach((crumb) => {
            this.props.crumbs.appendChild(crumb);
        });
        this.props.crumbs.appendChild(this.lastCrumb);

        this.runOn('onRevert');
    }

    buildTrigger() {
        const trigger = document.createElement('button');
        const triggerWrap = document.createElement('span');
        const triggerLabel = document.createElement('span');
        triggerLabel.classList = 'km-crumbs-trigger-label';
        trigger.classList = 'km-crumbs-trigger btn';
        triggerWrap.classList = 'km-crumbs-trigger-wrap';
        triggerLabel.appendChild(document.createTextNode(this.props.triggerText));

        if (this.props.triggerExtra) {
            triggerWrap.innerHTML += this.props.triggerExtra;
        }

        triggerWrap.appendChild(triggerLabel);
        trigger.appendChild(triggerWrap);

        trigger.addEventListener('click', (e) => {
            this.props.crumbs.classList.toggle('active');
        });

        return trigger;
    }

    wrapCrumbs() {
        const popup = document.createElement('ul');
        popup.classList = 'km-crumbs-popup';

        this.crumbs.forEach((crumb) => {
            popup.appendChild(crumb);
        });

        return popup;
    }

    clearCrumbs() {
        const parent = this.props.crumbs;
        while (parent.firstChild) {
            parent.removeChild(parent.lastChild);
        }
    }

    offClick(e) {
        if (!this.props.initOffclick) return;

        if (!this.props.crumbs.contains(e.target)) {
            this.reset();
        }
    }

    escClick() {
        if (!this.props.resetOnEsc) return;

        document.onkeydown = (evt) => {
            evt = evt || window.event;
            var isEscape = false;
            if ('key' in evt) {
                isEscape = evt.key === 'Escape' || evt.key === 'Esc';
            } else {
                isEscape = evt.keyCode === 27;
            }
            if (isEscape) {
                this.reset();
            }
        };
    }

    runOn(action) {
        const payload = {
            crumbsParent: this.props.crumbs,
            crumbs: this.crumbs,
            last: this.lastCrumb,
            mediaMatches: this.mediaMatches,
        };
        if (this.props[action]) {
            this.props[action](payload);
        }
    }

    reset() {
        this.props.crumbs.classList.remove('active');
        document.removeEventListener('click', this.offClick.bind(this), false);
        document.removeEventListener('click', this.escClick.bind(this), false);
    }

    isElement(element) {
        return element instanceof Element || element instanceof HTMLDocument;
    }

    watchMedia() {
        const { mediaVal } = this.props;
        const media = window.matchMedia(mediaVal);
        const mediaUpdate = () => {
            this.mediaMatches = media.matches;

            this.clearCrumbs();
            this.reset();
            this.runOn('onSwitch');

            if (this.mediaMatches) {
                if (this.props.crumbsClass) {
                    this.props.crumbs.classList.add(this.props.crumbsClass);
                }
                this.buildCrumbs();
            } else {
                if (this.props.crumbsClass) {
                    this.props.crumbs.classList.remove(this.props.crumbsClass);
                }
                this.revertCrumbs();
            }
        };

        media.addEventListener('change', mediaUpdate);
        mediaUpdate(media);
    }
}
