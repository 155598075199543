/**
 * Executes the provided callbacks based on the given media query status.
 *
 * @param {string} [mediaQuery="(max-width: 992px)"] - The media query to be evaluated.
 * @param {Object} [options] - The options object containing optional properties:
 *   @property {function} [onMatch] - The callback to execute when the media query matches.
 *   @property {function} [onNotMatch] - The callback to execute when the media query does not match.
 *   @property {function} [onChange] - The callback to execute whenever the media query status changes.
 *   @property {Array} [args] - The array of arguments to pass to the callbacks.
 */
export default function runOnMedia(mediaQuery = "(max-width: 992px)", options = {}) {
    const { onMatch, onNotMatch, onChange, args = [] } = options;
    const mediaQueryList = window.matchMedia(mediaQuery);

    function handleMediaQueryChange(event) {
        if (onChange) {
            onChange(event.matches, ...args);
        }

        if (event.matches) {
            if (onMatch) {
                onMatch(...args);
            }
        } else {
            if (onNotMatch) {
                onNotMatch(...args);
            }
        }
    }

    mediaQueryList.addEventListener("change", function (event) {
        handleMediaQueryChange(event);
    });

    handleMediaQueryChange(mediaQueryList);
}