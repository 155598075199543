import Modal from 'bootstrap/js/dist/modal';

/**
 * Handle site modals
 * - renders with time delay
 * - saves in session storage (optional)
 */
export default function modals() {
    const modals = [...document.querySelectorAll('[data-km-modal]')];

    modals.forEach(modal => {
        const props = "kmModal" in modal.dataset ? JSON.parse(modal.dataset.kmModal) : false;

        if (!props) {
            return;
        }

        const modalInstance = new Modal(modal, {});

        if ("timeDelay" in props) {
            const currValue = sessionStorage.getItem(modal.id);
            if (currValue === null || parseInt(currValue) === 0) {
                setTimeout(() => {
                    modalInstance.show();
                }, parseInt(props.timeDelay) * 1000);
            }
        }

        if ("useStorage" in props) {
            modal.addEventListener('hidden.bs.modal', function (event) {
                sessionStorage.setItem(modal.id, 1);
            })
        }
    });
}
