
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function splitContent() {
    const instances = gsap.utils.toArray('[data-content-animation]');
    const mm = gsap.matchMedia();

    /**
     * Handle Desktop View
     */
    const splitContentDesktop = () => {
        const movePercent = 50; // adjust as required to control distance to travel

        instances.forEach(instance => {
            const items = instance.querySelectorAll('[data-animate]');
            const tl = gsap.timeline({ paused: true });
            const distance = instance.dataset.contentAnimation === '1' ? [movePercent, -movePercent] : [-movePercent, movePercent];

            tl.from(items, {
                duration: 0.4,
                xPercent: gsap.utils.wrap(distance)
            });
            tl.from(items, { opacity: 0, duration: 0.9 }, 0)

            ScrollTrigger.create({
                trigger: instance,
                start: "top 80%",
                onEnter: () => tl.play()
            })

            ScrollTrigger.create({
                trigger: instance,
                start: "top 100%",
                onLeaveBack: () => tl.pause(0)
            })
        });
    }

    /**
     * Handle Mobile Animation
     */
    const splitContentMobile = () => {
        instances.forEach(instance => {
            const items = instance.querySelectorAll('[data-animate]');

            items.forEach(item => {
                const mobileTl = gsap.timeline({ paused: true });
                const target = item.querySelector('[data-animate-mobile]');

                if (!target) {
                    return;
                }

                if (target.dataset.animateMobile === 'scale') {
                    mobileTl.from(target, {
                        duration: 0.5,
                        opacity: 0,
                        scale: 0.8
                    })
                }

                if (target.dataset.animateMobile === 'move') {
                    mobileTl.from(target, {
                        duration: 0.5,
                        opacity: 0,
                        y: 100
                    })
                }

                ScrollTrigger.create({
                    trigger: item,
                    start: "top 90%",
                    onEnter: () => mobileTl.play()
                });

                ScrollTrigger.create({
                    trigger: item,
                    start: "top 100%",
                    onLeaveBack: () => mobileTl.pause(0)
                });
            });
        });
    }

    mm.add("(min-width: 992px)", splitContentDesktop);
    mm.add("(max-width: 991px)", splitContentMobile);

}