
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function fullScreenVerticalScroll() {
    const instances = gsap.utils.toArray('[data-full-screen-vertical-scroll]');
    const mm = gsap.matchMedia();

    /**
     * Handle Desktop View
     */
    const desktopAnimation = () => {
        instances.forEach(instance => {
            const rightSection = instance.querySelector('[data-right-section]');
            const rightElements = instance.querySelectorAll(['[data-right-element]:not(:first-of-type)']);
            const rightElementsAll = instance.querySelectorAll(['[data-right-element]']);
            const leftElements = instance.querySelectorAll(['[data-left-element]:not(:first-of-type)']);

            gsap.set(rightElements, {
                yPercent: 101,
                opacity: 0
            });

            ScrollTrigger.create({
                trigger: instance,
                start: "top top",
                end: "bottom bottom",
                pin: rightSection,
                markers: false
            })

            leftElements.forEach((item, index) => {
                const marker = item.querySelector('[data-left-marker]');

                const animation = gsap.timeline()
                    .to(rightElements[index], {
                        yPercent: 0,
                        opacity: 1
                    })
                    .set(rightElementsAll[index], {
                        autoAlpha: 0
                    });

                ScrollTrigger.create({
                    trigger: marker,
                    start: "top 80%",
                    end: "top 50%",
                    animation: animation,
                    scrub: true,
                    markers: false
                })
            });
        });
    }

    /**
     * Handle Mobile Animation
     */
    const mobileAnimation = () => {
        instances.forEach(instance => {
            const items = instance.querySelectorAll('[data-animate]');

            items.forEach(item => {
                const mobileTl = gsap.timeline({ paused: true });
                const target = item.querySelector('[data-animate-mobile]');

                if (!target) {
                    return;
                }

                if (target.dataset.animateMobile === 'scale') {
                    mobileTl.from(target, {
                        duration: 0.5,
                        opacity: 0,
                        scale: 0.8
                    })
                }

                if (target.dataset.animateMobile === 'move') {
                    mobileTl.from(target, {
                        duration: 0.5,
                        opacity: 0,
                        y: 100
                    })
                }

                ScrollTrigger.create({
                    trigger: item,
                    start: "top 90%",
                    onEnter: () => mobileTl.play()
                });

                ScrollTrigger.create({
                    trigger: item,
                    start: "top 100%",
                    onLeaveBack: () => mobileTl.pause(0)
                });
            });
        });
    }

    mm.add("(min-width: 992px)", desktopAnimation);
    mm.add("(max-width: 991px)", mobileAnimation);
}